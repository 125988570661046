var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md-layout",
      staticStyle: {
        "max-width": "2100px",
        margin: "auto",
        "overflow-x": "auto",
      },
    },
    [
      _c(
        "md-button",
        {
          staticClass: "md-simple md-just-icon md-round modal-default-button",
          staticStyle: {
            position: "absolute",
            top: "3px",
            left: "5px",
            "font-size": "30px !important",
          },
          on: { click: _vm.openShortlistHelp },
        },
        [_c("md-icon", [_vm._v("help")])],
        1
      ),
      _c(
        "div",
        { staticClass: "md-layout-item md-size-66 md-xsmall-size-80 mx-auto" },
        [
          _c(
            "simple-wizard",
            [
              _c(
                "wizard-tab",
                [
                  _c("template", { slot: "label" }, [
                    _c("div", { staticClass: "pc-view" }, [
                      _vm._v("Shortlist"),
                    ]),
                    _c("div", { staticClass: "mobi-view" }, [
                      _c("i", { staticClass: "fas fa-location-arrow" }),
                    ]),
                  ]),
                  _c("Shortlist", {
                    on: { bursaryApplicants: _vm.bursaryApplicants },
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }